import apiTool from "./index";
import ApiService from "./ApiService";

const LIST_REPOS = "api/v1/repo/list/";
const GET_AFFECTED_ELEMENTS = "api/v1/member/repo-affected-objects/";

export default {
    listRepos(payload: any) {
        ApiService.setHeader();
        return ApiService.post(LIST_REPOS, payload).then(response => {
            return response.data;
        });
    },
    addEntry(slug: any, payload: any) {
        ApiService.setHeader();
        return ApiService.post(`api/v1/${slug}/`, payload).then(response => {
            return response.data;
        });
    },
    patchEntry(id: any, slug: any, payload: any) {
        ApiService.setHeader();
        return ApiService.put(`api/v1/${slug}/${id}/`, payload).then(response => {
            return response.data;
        });
    },
    deleteEntry(id: any, slug: any) {
        ApiService.setHeader();
        return ApiService.delete(`api/v1/${slug}/${id}/`).then(response => {
            return response.data;
        });
    },
    replaceEntry(payload: any, slug: any) {
        const REPLACE_ENTRY = "api/v1/" + slug + "/delete-repo/";
        ApiService.setHeader();
        return ApiService.post(REPLACE_ENTRY, payload).then(response => {
            return response.data;
        });
    },
    getAffectedElements(payload: any) {
        ApiService.setHeader();
        return ApiService.post(GET_AFFECTED_ELEMENTS, payload).then(response => {
            return response.data;
        });
    }
};
