
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import repositoriesApi from "@/core/services/RepositoriesApi";
import { useStore } from "vuex";

export default defineComponent({
    name: "repositories",
    components: {},
    data() {
        return {
            periodFrom: "",
            selectedValue: "",
            periodTo: "",
            filterGradeSearch: "",
            loading: true,
            filters: {
                sex: "",
            },
            repoSearch: "",
            nbRepo: "",
            selectedRepos: [] as any,
            actualCol: "",
            actualFilter: "",
            currentPage: 1,
            totalPages: "",
            repos: [],
            repoTh: [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Elements", class: "min-w-150px", colName: "elements" },
                { label: "Last Modification", class: "min-w-60px", colName: "last_modified" },
            ],
            perm_pos: "",
        };
    },
    setup() {
        const resultsPerPage = 10;
        const router = useRouter();

        onMounted(() => {
            setCurrentPageBreadcrumbs("Repositories", []);
        });

        return { resultsPerPage, router };
    },
    mounted() {
        this.getRepos();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getRepos();
        },
        editRepo(repo_path: string, repo_name: string) {
            this.router.push({ name: "repo-details", params: { repo_path, repo_name } });
        },
        getRepos() {
            var payload = { p: this.currentPage, presult: this.resultsPerPage };
            repositoriesApi.listRepos(payload).then((response) => {
                this.repos = response.data.repositories_list;
                this.nbRepo = response.data.repositories_count;
                this.totalPages = response.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getRepos();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedRepos = [];
            this.currentPage = pageNumber;
            this.getRepos();
        },
    },
});
